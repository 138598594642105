const config = {
  AES_ENCRYPTION_KEY: "8b7e156228aed2a6abf7152985cf4f9c",
    LIA_CO_PILOT_CONFIG: {
      URL: 'lia-copilot.shipsy.io',
    },
    OIDC_SELF_BASE_URL: '.portal.shipsy.in',
    SSO_REDIRECT_BASE_URL: 'https://dtdcdashboardlogin.shipsy.io',
    USERPILOT_TOKEN: "NX-0ec2ed6c",
    USERPILOT_BLACKLISTED_ORGS: ["1"],
    CONSIGNMENT_LABEL_URL: "https://elabelprod.dtdc.in",
    API_BASE_URL: "https://pxapi.dtdc.in/api",
    RAVEN_BASE_URL: "https://r.shipsy.in/api",
    BUSINESS_BOOKING_API_URL: "https://obbv2prod.dtdc.in",
    ENVIRONMENT: "production",
    LTL_BASE_URL: "https://ltlapi.shipsy.in",
    LTL_POLLING_FREQUENCY: 20000,
    LOG_ROCKET_CONFIG: {
	 '1': [ 'PBLZ838', 'NF981_DEFAULT', 'C29043', 'N05_DEFAULT', 'C20_DEFAULT', 'C28393', 'OF919_DEFAULT', 'C17888', 'AV00109_DEFAULT', 'C26683', 'C20677', 'N20_DEFAULT', 'SHIPSY_SUPPORT_ARCHIT.GARG'],
    },
    LOG_ROCKET_KEY: "iuqv0m/shipsy",
    INTERCOM_KEY: "ntvhrj4m",
    SHOW_BOOKING: false,
  ROUTING_PLAYGROUND_URL: "https://routingv2.shipsy.in",
    SENTRY_KEY: "https://84e3a8767fbf69ba3252907a8328c75c@sentry.shipsy.io//56",
    ANALYTICS_DATA: [
      {
        src: "https://datastudio.google.com/embed/reporting/1jWTdi57pU6dssxwnEsdP021qUoTiG0_7/page/jp8o",
        colSpan: 24,
        height: "1400px",
      },
      {
        src: "https://datastudio.google.com/embed/reporting/1Sskj3hrItAnYeFNZmvGdBbGNZrIdqFeR/page/tj6o",
        colSpan: 24,
        height: "1400px",
      },
      {
        src: "https://datastudio.google.com/embed/reporting/1Y09hOEq1m-Nsvvdoh5l9P0RUJro3zILs/page/ui2o",
        colSpan: 24,
        height: "1400px",
      },
    ],
    GOOGLE_MAPS_API_KEY: "AIzaSyDx84-OepYg9YMmsxH7hJZqff2F2iwlG1Q",
    USE_HARDCODED_MASTER_DATA: false,
    DOMESTIC_STATUS_FETCH_POLLING_FREQUENCY: 30000,
    ROUTE_OPTIMISATION_BASE_URL: "https://pxapi.dtdc.in/api",
    RENDER_PERSISTENT_IFRAME_OPS: true,
    HIDE_ADD_TO_EXISTING_TRIP_RETAIL: true,
    HIDE_RETAIL_STORE_HUB_CHANGES: false,
    HIDE_RETAIL_SCHEDULING: true,
    QUERYBUILDER_CONFIG: {
      ANALYTICS_FRONTEND_URL: "analyticsdtdcdashboard.shipsy.io",
      BACKENDURL: "https://querybuilderdtdcprodapi.shipsy.in",
      DOWNLOAD_PAGE_POLL_INTERVAL: 10000,
    },
    API_PLAYGROUND_CONFIG: {
      URL: "https://apiplayground.shipsy.in",
    },
    FoodDashboard_DISPATCH_POLLING_FREQUENCY: 30000,
    ZAPSCALE_ORG_LIST: ["dtdc"],
    IS_ENV_PRODUCTION: true,
    GA_MID: "G-L6R11ZZ96S",
    GREEN_DEPLOYMENT_URLS: ["greenportal.shipsy.io"],
    AUTH_RSA_PUBLIC_KEY: "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA50+FNoYTr7zS06JTuBgyZ1ah2zExIhDoxcAPtDmq2mP3VwcmRV4AcFy39wo8suYNYPQqfkNC52Py/kLdB/FHYJyHSc1v0vXVV/27RRv33ygPfN5gVTmfDeRFTto3klQQM3SiUuYRhtYO9iV20xqpvLuiPLynRZlGZoXZYa+ZOLyITrj4+gRZGT5YAYiaprORq2TOJIYCdUwfGDawHhhsdwvfeJL89WcXiNrmjzVt1G1/7CmwYM72/pY0tfpXlN2IkhrCSUOMSLkDzPXFT76fF8o+O+PKGUDksguU0rCQFo2f6TWQqqJB/H+yXvkYIG1Yd+i/dvxl1A6ropHVVXNqkQIDAQAB-----END PUBLIC KEY-----",
    NEWRELIC_SNIPPET: ";window.NREUM||(NREUM={});NREUM.init={privacy:{cookies_enabled:false},ajax:{deny_list:[\"bam.nr-data.net\"]}};\n\n;NREUM.loader_config={accountID:\"1549482\",trustKey:\"1549482\",agentID:\"1120291042\",licenseKey:\"789c65050f\",applicationID:\"1120291042\"};\n;NREUM.info={beacon:\"bam.nr-data.net\",errorBeacon:\"bam.nr-data.net\",licenseKey:\"789c65050f\",applicationID:\"1120291042\",sa:1};\n",
    NEWRELIC_SAMPLING: 0.1,
  };
  
  module.exports.default = config;
