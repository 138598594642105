import * as moment from 'moment';
import {
  GENERIC_DASHBOARD_UPDATE_BUCKET_DATA,
GENERIC_DASHBOARD_ENABLE_BUCKET_LOADING,
GENERIC_DASHBOARD_DISABLE_BUCKET_LOADING,
GENERIC_DASHBOARD_UPDATE_GLOBAL_FILTER,
GENERIC_DASHBOARD_RESET_BUCKET_FILTER,
GENERIC_DASHBOARD_UPDATE_BUCKET_PAGINATION,
GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION,
GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA,
GENERIC_DASHBOARD_SET_BUCKET_FILTER,
GENERIC_DASHBOARD_APPLY_BUCKET_FILTER,
GENERIC_DASHBOARD_APPLY_OTHER_BUCKET_FILTER,
GENERIC_DASHBOARD_CANCEL_BUCKET_FILTER,
GENERIC_DASHBOARD_SET_ACTIVE_BUCKET,
GENERIC_DASHBOARD_RESET_ALL_BUCKETS_EXCEPT_FILTERS,
GENERIC_DASHBOARD_UPDATE_SUB_BUCKET_DATA,
GENERIC_DASHBOARD_BUCKET_RELOAD,
GENERIC_DASHBOARD_UPDATE_BUCKET_TABLE_DATA,
GENERIC_DASHBOARD_SET_BUCKET_SELECTED_ROW_KEYS,
GENERIC_DASHBOARD_UPDATE_FILTER_IN_ALL_BUCKETS,
GENERIC_DASHBOARD_APPLY_BUCKET_SORT,
GENERIC_DASHBOARD_APPLY_VIEW_SORT,
GENERIC_DASHBOARD_SET_TABLE_DATA_EMPTY,
SET_MASTER_DATA,
GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA_BUCKET_SPECIFIC,
 } from '../actions/constants';
import { getViewControl, getViewBuckets } from '../utils/genericConfig/utils';
import { MAP_EDIT_VIEW_TYPE } from 'src/components/pages/retail/trip/TripManagerMapEdit/TripManagerMapEditUtils';

const defaultBucketAppliedFilters = {
  dayPartFilter: undefined,
  finalConsignmentStatus: undefined,
  nonRegularOrders: undefined,
};
const defaultBucketSort = {
  descendingOrder:true,
  sortBy:'created_at',
};
const defaultPagination =  {
  currentPageNumber: 1,
  nextOrPrev: 'first',
  perPageCount : 50,
  paginationParams : [],
};
const noPagination = {
  currentPageNumber: 1,
  nextOrPrev: 'first',
  perPageCount : undefined,
  paginationParams : [],
};
const defaultMapListView = {
  isMapView : false,
  mapWidgetsToShow : [],
};
const defaultBucketState = {
  sort : defaultBucketSort,
  shouldUpdate:true,
  subBuckets : [],
  pagination:defaultPagination,
  prevPagination:defaultPagination,
  data:[],
  selectedRowKeys : [],
  selectedRecords : [],
  isLoading:false,
  appliedFilters: defaultBucketAppliedFilters,
  mapListView: defaultMapListView,
};
const getDefaultBucketState = (bucket) => {
  let bucketState: any = defaultBucketState;
  if (bucket.hidePagination) {
    bucketState = {...bucketState,
      pagination:noPagination,
      prevPagination:noPagination,
    };
  }
  if (bucket.appliedFilters) {
    bucketState = {...bucketState,
      appliedFilters: {
        ...bucketState.appliedFilters,
        ...bucket.appliedFilters,
      },
    };
  }
  if(bucket.mapListView){
    bucketState = {...bucketState,
      mapListView:{
        ...bucketState.mapListView,
        ...bucket.mapListView,
      },
    };
  }
  return bucketState;
};
const getDefaultBuckets = (viewType) => {
  const buckets = {};
  getViewControl(viewType).buckets.forEach((x) => {
    const bucketConfig = getViewBuckets(viewType)[x];
    buckets[x] = {
      ...getDefaultBucketState(getViewBuckets(viewType)[x]),
      sort: {
      ...defaultBucketSort,
      sortBy: bucketConfig.defaultSortBy || defaultBucketSort.sortBy,
      },
    };
  });
 return buckets;
};
const defaultGlobalAppliedFilters = {
  searchType: 'tripReferenceNumber',
  searchValue: '',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  dateFilterType: 'last_main_event_time',
};
const defaultState = {
  crm:{
    activeBucket : 'crm_default',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      crm_default:defaultBucketState,
    },
  },
  logistics: {
    activeBucket : 'crm_default',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      crm_default:defaultBucketState,
    },
  },
  domestic:{
    summaryMetrics: {},
    activeBucket : 'ltl_track',
    appliedFilters: {},
    globalAppliedFilters: {
      searchType:'awbNumber',
      searchValue:'',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('domestic'),
    },
  },
  retailPlanning:{
    summaryMetrics: {},
    activeBucket : 'retail_dc_all',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      scheduledDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('retailPlanning'),
    },
  },
  retailUpcountry:{
    summaryMetrics: {},
    activeBucket : 'retail_all',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('retailUpcountry'),
    },
  },
  retailStore:{
    summaryMetrics: {},
    activeBucket : 'retail_store_all',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('retailStore'),
    },
  },
  retailTripsPrimary : {
    globalAppliedFilters:defaultGlobalAppliedFilters,
    activeBucket : 'retail_all',
    summaryMetrics:{
    },
    buckets:{
      ...getDefaultBuckets('retailTripsPrimary'),
    },
  },
  ondemand:{
    summaryMetrics: {},
    activeBucket : 'ondemand_all_orders',
    appliedFilters: {},
    globalAppliedFilters: {
      searchType:'',
      searchValue:'',
      startDate: moment().subtract(0, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'booking_time',
    },
    buckets : {
      ...getDefaultBuckets('ondemand'),
    },
  },
  on_demand_cod:{
    summaryMetrics: {},
    activeBucket : 'active',
    appliedFilters: {},
    globalAppliedFilters: {
      startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'booking_time',
    },
    buckets : {
      ...getDefaultBuckets('on_demand_cod'),
    },
  },
  retailTripsSecondary : {
    globalAppliedFilters:defaultGlobalAppliedFilters,
    activeBucket : 'retail_all',
    summaryMetrics:{
    },
    buckets:{
      ...getDefaultBuckets('retailTripsSecondary'),
    },
  },
  metrics:{
    activeBucket : 'crm_summary_metrics',
    globalAppliedFilters: {
      startDate: moment().subtract(30, 'day').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'completion_time',
    },
    buckets : {
      crm_summary_metrics: defaultBucketState,
    },
  },
  supportPortal:{
    activeBucket : 'retail_dc_all',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
    },
    buckets : {
      retail_dc_all: defaultBucketState,
    },
  },
  thirdParty:{
    activeBucket : 'third_party_all',
    summaryMetrics : {},
    globalAppliedFilters: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets:{
      ...getDefaultBuckets('thirdParty'),
    },
  },
  tms_vehicle:{
    summaryMetrics: {},
    activeBucket : 'tms_vehicle_expected',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'last_status_change_time',
    },
    buckets : {
      ...getDefaultBuckets('tms_vehicle'),
    },
  },
  tms_vehicle_attendance:{
    summaryMetrics: {},
    activeBucket : 'tms_vehicle_attendance',
   globalAppliedFilters: {
    searchType:'referenceNumber',
    searchValue:'',
      startDate: moment().subtract(6, 'day').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'attendance_date',
    },
    buckets : {
      ...getDefaultBuckets('tms_vehicle_attendance'),
    },
  },
  tms_ist:{
    summaryMetrics: {},
    activeBucket : 'tms_ist_created',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'last_status_change_time',
    },
    buckets : {
      ...getDefaultBuckets('tms_ist'),
    },
  },
  tms_bag:{
    summaryMetrics: {},
    activeBucket : 'tms_bag_all',
    globalAppliedFilters: {
      startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'status_update_time',
    },
    buckets : {
      tms_bag_all: {
        ...defaultBucketState,
        appliedFilters: {
          searchType: 'reference_number',
        },
      },
    },
  },
  tms_setup_route: {
    activeBucket : 'tms_setup_routes',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tms_setup_route'),
    },
  },
  tms_setup_connection: {
    activeBucket : 'tms_setup_connections',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tms_setup_connection'),
    },
  },
  tms_setup_exception: {
    activeBucket : 'tms_setup_exception_vehicle',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tms_setup_exception'),
    },
  },
  tms_setup_bagging: {
    activeBucket : 'tms_setup_bagging_all',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tms_setup_bagging'),
    },
  },
  setup_booking_masters: {
    activeBucket : 'tat',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('setup_booking_masters'),
    },
  },
  setup_courier_account_integrations : {
    activeBucket : 'courierPricing',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('setup_courier_account_integrations'),
    },
  },
  setup_routing: {
    activeBucket : 'routing',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('setup_routing'),
    },
  },
  shelf: {
    activeBucket : 'shelf',
    globalAppliedFilters: {
      startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'last_main_event_time',
    },
    buckets : {
      shelf: defaultBucketState,
    },
  },
  crate: {
    activeBucket : 'crate',
    globalAppliedFilters: {
      startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'updated_at',
    },
    buckets : {
      crate: defaultBucketState,
    },
  },
  logistics_express: {
    summaryMetrics: {},
    activeBucket : 'logistics_express_all',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      scheduledDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('logistics_express'),
    },
  },
  manifest_declaration: {
    summaryMetrics: {},
    activeBucket : 'all',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      scheduledDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'departureDate',
    },
    buckets : {
      ...getDefaultBuckets('manifest_declaration'),
    },
  },
  consolidated_planning: {
    summaryMetrics: {},
    activeBucket : 'planning_unplanned',
    activeSubBucket : '_all',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      scheduledDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('consolidated_planning'),
    },
  },
  tms_setup_gate: {
    activeBucket : 'tms_setup_gates',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tms_setup_gate'),
    },
  },
  tms_setup_ewb_threshold_master: {
    activeBucket : 'tms_setup_ewb_threshold_master',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tms_setup_ewb_threshold_master'),
    },
  },
  tms_setup_dock: {
    activeBucket : 'tms_setup_docks',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tms_setup_dock'),
    },
  },
  tms_setup_vehicle_documents: {
    activeBucket : 'tms_setup_vehicle_documents',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tms_setup_vehicle_documents'),
    },
  },
  rider_recon: {
    activeBucket: 'rider_reconciliation',
    globalAppliedFilters: {
      searchType: 'worker_name',
      searchList: [],
      tripStartDate: moment().format('YYYY-MM-DD'),
      tripEndDate: moment().format('YYYY-MM-DD'),
      taskStartDate: moment().format('YYYY-MM-DD'),
      taskEndDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'trip_completion_date',
      status: 'nonsettled',
    },
    buckets: {
      ...getDefaultBuckets('rider_recon'),
    },
  },
  approval_requests: {
    activeBucket: 'pending',
    globalAppliedFilters: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
      request_type: 'updateaddress',
      waybill_type: 'consignment',
    },
    summaryMetrics: {},
    buckets: {
      ...getDefaultBuckets('approval_requests'),
    },
  },
  calls_overview: {
    summaryMetrics: {},
    activeBucket : 'call_center_overview',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().subtract(29, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('calls_overview'),
    },
  },
  my_calls: {
    summaryMetrics: {},
    activeBucket : 'call_center_employee',
    globalAppliedFilters: {
      searchType:'referenceNumber',
      searchValue:'',
      startDate: moment().subtract(29, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('my_calls'),
    },
  },
  reasonCategoryMaster: {
    activeBucket : 'reasonCategoryMaster',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('reasonCategoryMaster'),
    },
  },
  ltlNDRMaster: {
    activeBucket : 'ltlNDRMaster',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('ltlNDRMaster'),
    },
  },
  packagingTypeMaster: {
    activeBucket : 'packagingTypeMaster',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('packagingTypeMaster'),
    },
  },
  partialDeliveryReasons: {
    activeBucket : 'partialDeliveryReasons',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('partialDeliveryReasons'),
    },
  },
  lateDeliveryReasons: {
    activeBucket : 'lateDeliveryReasons',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('lateDeliveryReasons'),
    },
  },
  courierPartnerChangeReasons: {
    activeBucket : 'courierPartnerChangeReasons',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('courierPartnerChangeReasons'),
    },
  },
  serviceTaskMapping: {
    activeBucket : 'serviceTaskMapping',
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('serviceTaskMapping'),
    },
  },
  tripContracts: {
    activeBucket : 'tripContracts',
    appliedFilters: {},
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('tripContracts'),
    },
  },
  appUserApproval: {
    activeBucket: 'pending',
    globalAppliedFilters: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
      request_type: 'createworker',
      waybill_type: 'worker',
    },
    summaryMetrics: {},
    buckets: {
      ...getDefaultBuckets('appUserApproval'),
    },
  },
  currencyConversion: {
    activeBucket : 'currencyConversion',
    appliedFilters: {},
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('currencyConversion'),
    },
  },
  supply_request: {
    activeBucket : 'supply_request_all',
    globalAppliedFilters: {
      searchType:'supplyRequestId',
      searchValue:'',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets('supply_request'),
    },
  },
  [MAP_EDIT_VIEW_TYPE]: {
    summaryMetrics: {},
    activeBucket : getViewControl(MAP_EDIT_VIEW_TYPE).buckets[0],
    globalAppliedFilters: {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      scheduledDate: moment().format('YYYY-MM-DD'),
      dateFilterType: 'created_at',
    },
    buckets : {
      ...getDefaultBuckets(MAP_EDIT_VIEW_TYPE),
    },
  },
  addressRegister: {
    activeBucket : 'addressRegister',
    appliedFilters: {},
    globalAppliedFilters: {},
    buckets : {
      ...getDefaultBuckets('addressRegister'),
    },
  },
  customerManage: {
    activeBucket: 'customerManage',
    appliedFilters: {},
    globalAppliedFilters: {},
    buckets: {
      ...getDefaultBuckets('customerManage'),
    },
  },
  supplementaryServices: {
    activeBucket: 'supplementaryServices',
    appliedFilters: {},
    globalAppliedFilters: {},
    buckets: {
      ...getDefaultBuckets('supplementaryServices'),
    },
  },
  itemUnitMaster: {
    activeBucket: 'itemUnitMaster',
    appliedFilters: {},
    globalAppliedFilters: {},
    buckets: {
      ...getDefaultBuckets('itemUnitMaster'),
    },
  },
};
export default function (state:any = defaultState, action) {

  switch (action.type) {
    case GENERIC_DASHBOARD_UPDATE_BUCKET_DATA: {
      return {
        ...state,
        // Data of all columns will be here
        // Need to filter on the basis of columns_to_display
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              shouldUpdate:false,
              data: action.data.page_data,
              pagination: {
                ...state[action.viewType].buckets[action.bucket].pagination,
                lastItemIdPrev: action.data ? action.data.lastItemIdPrev : 0,
                isNextPresent: action.data ? action.data.isNextPresent : null,
                pageNumber: action.data ? action.data.pageNumber : 1,
                lastItemIdNext: action.data ? action.data.lastItemIdNext : 0,
                lastSortedColumnValuePrev: action.data ?
                  action.data.lastSortedColumnValuePrev : 0,
                lastSortedColumnValueNext: action.data ?
                  action.data.lastSortedColumnValueNext : 0,
                lastSortByKey:action.data ? action.data.lastSortByKey : 0,
                paginationParams : action.data.paginationParams ?
                  action.data.paginationParams : [],
                paginationRecordList: action.data.paginationRecordList ?
                action.data.paginationRecordList : undefined,
              },
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_ENABLE_BUCKET_LOADING: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              isLoading: true,
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_DISABLE_BUCKET_LOADING: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              isLoading: false,
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_UPDATE_GLOBAL_FILTER: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          globalAppliedFilters: {
            ...state[action.viewType].globalAppliedFilters,
            ...action.data,
          },
        },
      };
    }

    case GENERIC_DASHBOARD_UPDATE_FILTER_IN_ALL_BUCKETS: {
      const resetBuckets = defaultState[action.viewType].buckets;
      const bucketKeys = Object.keys(resetBuckets);
      bucketKeys.forEach((x) => {
        resetBuckets[x] = {
          ...resetBuckets[x],
          appliedFilters : {
            ...state[action.viewType].buckets[x].appliedFilters,
            ...action.data,
          },
        };
      });
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:resetBuckets,
        },
      };
    }

    case GENERIC_DASHBOARD_SET_BUCKET_FILTER: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              appliedFilters: action.data,
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_APPLY_BUCKET_FILTER: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              appliedFilters: action.data,
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_APPLY_OTHER_BUCKET_FILTER: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              appliedFilters: {
                ...state[action.viewType].buckets[action.bucket].appliedFilters,
                ...action.data,
              },
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_RESET_BUCKET_FILTER: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              appliedFilters :
                defaultState[action.viewType].buckets[action.bucket].appliedFilters,
            },
          },
        },
      };
    }
    // No need for this - to be deprecated
    case GENERIC_DASHBOARD_CANCEL_BUCKET_FILTER: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]: action.data,
          },
        },
      };
    }
    case GENERIC_DASHBOARD_UPDATE_BUCKET_PAGINATION: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              pagination : {
                ...state[action.viewType].buckets[action.bucket].pagination,
                ...action.data,
              },
              prevPagination : {
                ...state[action.viewType].buckets[action.bucket].pagination,
                ...action.data,
              },
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              pagination : {
                ...defaultState[action.viewType].buckets[action.bucket].pagination,
                perPageCount: state[action.viewType].buckets[action.bucket].pagination.perPageCount,
              },
              prevPagination : {
                ...defaultState[action.viewType].buckets[action.bucket].pagination,
                perPageCount: state[action.viewType].buckets[action.bucket].pagination.perPageCount,
              },
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_SET_ACTIVE_BUCKET : {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          activeBucket : action.data,
        },
      };
    }
    case GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          summaryMetrics: action.data,
        },
      };
    }
    case GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA_BUCKET_SPECIFIC: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          summaryMetrics: {
            ...state[action.viewType].summaryMetrics,
            [action.bucket]: action.data?.[action.bucket],
            timeUnit: action.data?.timeUnit,
            totalAmount: action.data?.totalAmount,
            totalServiceTime: action.data?.totalServiceTime,
            totalVolume: action.data?.totalVolume,
            volumeUnit: action.data?.volumeUnit,
          },
        },
      };
    }
    case GENERIC_DASHBOARD_RESET_ALL_BUCKETS_EXCEPT_FILTERS : {
      const resetBuckets = defaultState[action.viewType].buckets;
      const bucketKeys = Object.keys(resetBuckets);
      bucketKeys.forEach((x) => {
        resetBuckets[x] = {
          ...resetBuckets[x],
          appliedFilters : state[action.viewType].buckets[x].appliedFilters,
          sort : state[action.viewType].buckets[x].sort,
        };
      });
      return {
        ...state,
        [action.viewType] : {
          ...state[action.viewType],
          buckets:resetBuckets,
        },
      };
    }
    case GENERIC_DASHBOARD_UPDATE_SUB_BUCKET_DATA: {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              subBuckets : [...action.data],
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_BUCKET_RELOAD : {
      const newBuckets = {};
      for (const key in state[action.viewType].buckets) {
        if (state[action.viewType].buckets.hasOwnProperty(key)) {
          if (key === action.bucket) {
            newBuckets[key] = {
              ...state[action.viewType].buckets[key],
              pagination : {
                ...state[action.viewType].buckets[key].prevPagination,
              },
            };
          } else {
            newBuckets[key] = {
              ...state[action.viewType].buckets[key],
              shouldUpdate: action.resetOtherBuckets
              || state[action.viewType].buckets[key].shouldUpdate,
            };
          }
        }
      }
      state[action.viewType].buckets;
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          analyticsReload: !state[action.viewType].analyticsReload,
          buckets: newBuckets,
        },
      };
    }
    case GENERIC_DASHBOARD_UPDATE_BUCKET_TABLE_DATA : {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              data:  [...action.data],
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_SET_BUCKET_SELECTED_ROW_KEYS : {
      return {
        ...state,
        // Data of all columns will be here
        // Need to filter on the basis of columns_to_display
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              selectedRowKeys : action.data,
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_APPLY_BUCKET_SORT : {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              sort : action.data,
            },
          },
        },
      };
    }
    case GENERIC_DASHBOARD_APPLY_VIEW_SORT : {
      const resetBuckets = defaultState[action.viewType].buckets;
      const bucketKeys = Object.keys(resetBuckets);
      bucketKeys.forEach((x) => {
        const sort = state[action.viewType].buckets[x].sort;
        resetBuckets[x] = sort.bucketSort ?
        {
          ...resetBuckets[x],
          appliedFilters : state[action.viewType].buckets[x].appliedFilters,
          sort,
        } :
        {
          ...resetBuckets[x],
          appliedFilters : state[action.viewType].buckets[x].appliedFilters,
          sort : {
            ...action.data,
          },
        };
      });
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:resetBuckets,
        },
      };
    }
    case GENERIC_DASHBOARD_SET_TABLE_DATA_EMPTY : {
      return {
        ...state,
        [action.viewType]:{
          ...state[action.viewType],
          buckets:{
            ...state[action.viewType].buckets,
            [action.bucket]:{
              ...state[action.viewType].buckets[action.bucket],
              shouldUpdate:false,
              data: [],
            },
          },
        },
      };

    }
    case SET_MASTER_DATA: {
      return {
        ...state,
        retailPlanning:{
          ...state.retailPlanning,
          globalAppliedFilters: {
            ...state.retailPlanning.globalAppliedFilters,
            dateFilterType: getDefaultDateFilterType(action.data),
          },
        },
        retailTripsPrimary:{
          ...state.retailTripsPrimary,
          globalAppliedFilters: {
            ...state.retailTripsPrimary.globalAppliedFilters,
            dateFilterType: getDefaultDateFilterTypeTrips(action.data),
          },
        },
        retailTripsSecondary:{
          ...state.retailTripsSecondary,
          globalAppliedFilters: {
            ...state.retailTripsSecondary.globalAppliedFilters,
            dateFilterType: getDefaultDateFilterTypeTrips(action.data),
          },
        },
        ...getDefaultGlobalAndBucketFilters({ state, action }),
      };
    }
    default: return state;

  }
}

function getDefaultDateFilterType(masterData) {
  if(masterData.retail_features_config &&
    masterData.retail_features_config.scheduled_deliveries){
      return 'scheduled_at';
    }
    return 'created_at';
}

function getDefaultDateFilterTypeTrips(masterData) {
  if(masterData.retail_features_config &&
    masterData.retail_features_config.scheduled_deliveries){
      return 'scheduled_at';
    }
    return 'last_main_event_time';
}

function getDateFilterDependentValue(masterData,state,viewType){
  const options = masterData?.default_configuration_options;
  const valueToSet = options?.[viewType]?.default_date_filter_type;
  const dateOptions = getViewControl(viewType)?.dateFilterOptions?.map((dateType) => dateType.key);
  if(dateOptions && valueToSet && dateOptions.indexOf(valueToSet) !== -1 ){
    return valueToSet;
  }
  return null;
}
function getDefaultSortGenericCN(masterData,state,viewType,bucketKey){
  const sortBy = getDateFilterDependentValue(masterData,state,viewType);
  return sortBy || state?.[viewType]?.buckets?.[bucketKey]?.sort?.sortBy;
}

function getDefaultDateFilterTypeGenericCN(masterData,state,viewType){
  const dateFilterType = getDateFilterDependentValue(masterData,state,viewType);
  return dateFilterType || state?.[viewType]?.globalAppliedFilters?.dateFilterType;
}

function getDefaultBucketType (masterData,state,viewType){
  const options = masterData?.default_configuration_options;
  const valueToSet = options?.[viewType]?.default_bucket;
  return valueToSet || state?.[viewType]?.activeBucket;
}

function getDefaultGlobalAndBucketFilters({
  state, action,
}) {
  return Object.keys(defaultState)?.reduce((newState, viewType) => {
    const buckets = getViewControl(viewType)?.buckets || [];
    const {
      dayPartList,
      nonRegularOrderList,
      orderStatusList,
    } = action.data?.default_filter_values_for_analytics || {};
    const {
      dayPartList: dayPartListOptions,
    } = action.data?.filter_values_master_for_analytics || {};
    /* id is of the form [number, number],
       antd option matching fails even if both the numbers match.
       Hence, the labels aren't correcly shown in the dropdown.
       To fix that - we're finding out the same
       array object from the options' list,
       so that match by reference succeeds & label is shown. */
    const defaultOptionForDayPartFilter = dayPartListOptions?.filter(option => {
      return dayPartList?.some(defaultOption => {
        const everyOptionIdInDefaultVal =
          option?.id?.every(val => defaultOption?.id?.includes(val));
        const everyDefaultValIdInOption =
          defaultOption?.id?.every(val => option?.id?.includes(val));
        const atLeastOneDefaultOptionMatchesOption =
          everyOptionIdInDefaultVal && everyDefaultValIdInOption;
        return atLeastOneDefaultOptionMatchesOption;
      });
    });
    newState[viewType] = {
      ...state?.[viewType],
      activeBucket: getDefaultBucketType(action.data, state, viewType),
      globalAppliedFilters: {
        ...state?.[viewType]?.globalAppliedFilters,
        dateFilterType: getDefaultDateFilterTypeGenericCN(action.data,state,viewType),
        startDate: action.data?.analytics_default_date_range ?
          moment().subtract(action.data?.analytics_default_date_range - 1 || 0, 'day').format('YYYY-MM-DD') :
          state?.[viewType]?.globalAppliedFilters.startDate,
      },
      buckets: {
        ...state?.[viewType]?.buckets,
        ...buckets?.reduce((bucketsData, bucketKey) => {
          bucketsData[bucketKey] = {
            ...state?.[viewType]?.buckets?.[bucketKey],
            sort: {
              ...state?.[viewType]?.buckets?.[bucketKey]?.sort,
              sortBy: getDefaultSortGenericCN(action.data,state,viewType,bucketKey),
            },
            appliedFilters: {
              ...state?.[viewType]?.buckets?.[bucketKey]?.appliedFilters,
              dayPartFilter: defaultOptionForDayPartFilter?.map(el => el?.id),
              nonRegularOrders: nonRegularOrderList?.map(el => el?.id),
              finalConsignmentStatus: orderStatusList?.map(el => el?.id),
            },
          };
          return bucketsData;
        }, {}),
      },
    };
    return newState;
  }, {});
}
